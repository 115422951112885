import React from 'react';
import { motion, useCycle } from 'framer-motion';
import styled from 'styled-components';

// import Header from './Header copy';
import MenuToggle from './MenuToggle';

const Sidebar = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 11;
  background: ${props => props.theme.colors.yellow};
`;

const Nav = styled(motion.nav)`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

const sidebarVarients = {
  open: {
    clipPath: `polygon(10% 0px, 100% 0px, 100% 100%, 40% 100%)`,
    transition: {
      type: 'spring',
      stiffness: 100,
    },
  },
  closed: {
    clipPath: 'polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%)',
    transition: {
      // delay: 0.5,
      type: 'spring',
      stiffness: 200,
      damping: 40,
      duration: 5,
    },
  },
};

const Header = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <Nav initial={false} animate={isOpen ? 'open' : 'closed'}>
      <Sidebar variants={sidebarVarients} />
      <MenuToggle toggle={() => toggleOpen()} />
    </Nav>
  );
};

export default Header;
