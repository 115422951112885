import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Button = styled(motion.button)`
  background: ${props => props.theme.colors.offBlack};
  stroke: ${props => props.theme.colors.yellow};
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 9999;
  padding: 0;
`;

const Path = ({ variants, transition, d }) => (
  <motion.path
    fill="transparent"
    strokeWidth="10"
    strokeLinecap="round"
    variants={variants}
    transition={transition}
    d={d}
  />
);

const MenuToggle = ({ toggle }) => (
  <Button type="button" onClick={toggle}>
    <svg width="24" height="24" viewBox="0 0 100 100">
      <Path
        variants={{
          closed: { d: 'M 5 10 L 95 10' },
          open: { d: 'M 5 90 L 95 10' },
        }}
      />
      <Path
        d="M 5 50 L 95 50"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 5 90 L 95 90' },
          open: { d: 'M 5 10 L 95 90' },
        }}
      />
    </svg>
  </Button>
);

export default MenuToggle;
