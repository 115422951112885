import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../styles/GlobalStyles';
import SEO from './SEO';
import theme from '../../styles/theme';
import media from '../../styles/breakpoints';

import Header from './Header';

const Border = styled.div`
  height: 100%;
  width: 100vw;
  border: 16px solid ${props => props.theme.colors.yellow};

  ${media.lessThan('sm')`
    border-width: 8px;
  `}
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <SEO />
      <Border>
        <Header />
        {children}
      </Border>
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
