module.exports = [{
      plugin: require('/tmp/576eb4d3/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/576eb4d3/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/tmp/576eb4d3/src/components/Layout.js"},
    },{
      plugin: require('/tmp/576eb4d3/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"quality":90}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1rem"}}]},
    },{
      plugin: require('/tmp/576eb4d3/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-xxxxxxxx-x"},
    },{
      plugin: require('/tmp/576eb4d3/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
